import Layout from '@/components/Layout'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { NextPageContext } from 'next'
import { getCsrfToken, signIn } from 'next-auth/react'
import Head from 'next/head'
import { FunctionComponent } from 'react'

interface LoginProps {
  csrfToken: string
}

const Login: FunctionComponent<LoginProps> = ({ csrfToken }) => {
  return (
    <div>
      <Head>
        <title>Sjakk.no - Min side - Logg inn</title>
        <meta name="description" content="Sjakk.no - Min side - Logg inn" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout>
        <div className="h-full flex flex-col justify-center text-center">
          <h1 className="font-serif text-2xl md:text-4xl mb-16">
            Sjakk.no - Logg inn
          </h1>
          <p className="font-normal md:font-medium">
            Logg inn for å se din medlemsskapsstatus, kontaktinformasjon og
            FIDE-ratings.
          </p>
          <form method="post" action="/api/auth/signin/email">
            <div className="flex flex-col items-center gap-4 mt-32">
              <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
              <label className="sr-only" htmlFor="email">
                E-post
              </label>
              <input
                required
                className="border-2 border-black bg-blue-light/30 w-3/4"
                placeholder="E-postadresse"
                type="email"
                id="email"
                name="email"
              />
              <button
                type="submit"
                className="disabled:opacity-25 disabled:cursor-not-allowed flex items-center px-24 pt-12 pb-12 mt-16 font-medium transition duration-150 rounded-full bg-blue-dark text-blue-light text-sm w-max hover:bg-blue-medium"
              >
                Logg inn med e-post
                <svg
                  className="ml-6 transition duration-150 transform group-hover:translate-x-6"
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9H18V7H0V9Z"
                    fill="#CFE0FF"
                  ></path>
                </svg>
              </button>
            </div>
          </form>
        </div>
        <div className="text-center text-sm mt-48 md:mt-0">
          Ved å fortsette innlogging godtar du sjakk.no sin{' '}
          <a
            className="underline hover:underline hover:text-blue-dark"
            href="https://www.sjakk.no/forbund/lover-og-reglementer/personvernerkl%C3%A6ring"
          >
            personvernsærklering og vilkår for bruk
          </a>
        </div>
      </Layout>
    </div>
  )
}

export async function getServerSideProps(context: NextPageContext) {
  const csrfToken = await getCsrfToken(context)
  return {
    props: { csrfToken },
  }
}

export default Login
